<template>
  <div class="sg-web-app" @click="topClick">
    <div class="sg-web-container">
      <NavMenu></NavMenu>
      <div class="sg-web-content">
        <router-view />
      </div>
      <!-- <LoginBox></LoginBox> -->
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavMenu from "@/components/Web/NavMenu.vue";
import Footer from "@/components/Web/Footer.vue";
// import LoginBox from "@/components/Web/LoginBox.vue";

export default {
  name: "Layout",
  props: {},
  components: {
    Footer,
    // LoginBox,
    NavMenu,
  },
  computed: mapState({
    isLogin: (state) => state.isLogin,
    loading: (state) => state.loading,
  }),
  data: () => {
    return {};
  },
  methods: {
    topClick() {
      this.$store.commit("web/updateData", {
        name: "showSearch",
        data: false,
      });
    },
  },
  mounted() {
    let width = window.innerWidth;
    if (width < 1440) {
      let scrollY = (1440 - width) / 2;
      this.$store.commit('updateData', {
        name: 'scrollY',
        key: scrollY
      })
      this.$nextTick(() => {
        setTimeout(function() {
          window.scrollTo(scrollY, 0);
        }, 300)
      });
      
    }
    // this.$store.commit('scrollYWhenSM')

    // console.log(document.body.clientWidth)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-web-content {
  // padding-top: 60px;
  min-height: calc(100vh - 164px);
}
// .sg-web-app {
//   min-width: 1440px;
// }
</style>
