<template>
  <div>
    <div
      class="sg-top-nav-box sg-fix-top sg-w100"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
      style="z-index: 100"
    >
      <el-row
        :class="{
          'sg-route-index': touchTopIndex,
          'sg-white-bg': !touchTopIndex,
        }"
      >
        <el-col :sm="{ span: 24 }" :xs="{ span: 24 }" class="sg-space-between">
          <div class="sg-align-center" style="margin-left: 42px">
            <el-button
              size="small"
              icon="el-icon-menu"
              class="sg-margin-sm sg-font-icon"
              v-if="screenSize == 'xs'"
              @click="switchShowMenu"
            ></el-button>
            <a href="/" v-else>
              <img
                src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo-4.png"
                class="sg-logo"
                style="width: 104px; height: 28px"
              />
            </a>
          </div>
          <div :class="{ 'sg-pad-right': screenSize == 'xs' }">
            <div
              class="sg-flex-center"
              style="position: relative"
              @mouseleave="navLeave"
            >
              <div style="position: relative" class="sg-menu-box">
                <div>
                  <div
                    :class="{
                      'sg-nav-menu sg-flex': true,
                      'sg-touch-top': touchTopIndex,
                    }"
                  >
                    <div
                      v-for="(item, index) in menus"
                      :key="index"
                      @mouseenter="navEnter(item.path)"
                    >
                      <a
                        :href="item.path"
                        style="text-decoration: none"
                        class="sg-nav-link"
                        >{{ item.name }}</a
                      >
                    </div>
                  </div>
                  <div
                    :style="{
                      transition: 'all 0.3s',
                      width: navBarW,
                      left: navBarL,
                      height: '5px',
                      background: touchTopIndex ? '#fff' : '#c73437',
                      position: 'absolute',
                      top: '0',
                    }"
                  ></div>
                </div>
                <!-- <el-menu
                  :collapse-transition="false"
                  id="sg-top-nav"
                  :default-active="activeIndex"
                  class="sg-font-14px"
                  mode="horizontal"
                  @select="handleSelect"
                  :background-color="'transparent'"
                  :router="true"
                  :text-color="touchTopIndex ? 'white' : '#444'"
                  :active-text-color="'#c31a1f'"
                >
                  <template v-for="(item, index) in menus">
                    <el-submenu
                      :index="item.path"
                      v-if="item.children"
                      :key="index"
                      popper-class="sg-pop-menu"
                      :show-timeout="0"
                      :hide-timeout="0"
                    >
                      <template slot="title">{{ item.name }}</template>
                      <el-menu-item
                        :index="sub_item.path"
                        :key="index + '-' + sub_index"
                        v-for="(sub_item, sub_index) in item.children"
                        >{{ sub_item.name }}</el-menu-item
                      >
                    </el-submenu>
                    <el-menu-item :index="item.path" v-else :key="index">{{
                      item.name
                    }}</el-menu-item>
                  </template>
                </el-menu> -->
              </div>
              <div
                :class="{
                  'sg-sub-menu-box': true,
                  'sg-touch-top': touchTopIndex,
                }"
              >
                <div
                  :key="index"
                  v-for="(item, index) in twoMenus"
                  class="sg-flex sg-sub-memu"
                  :style="{
                    left: item.left,
                    position: 'absolute',
                    background: 'rgba(0, 0, 0, 0.6)',
                    bottom: hoverMenu == item.key ? '-100%' : '1px',
                    height: '67px',
                    width: '100%',
                    transition: 'all 0.3s',
                    'z-index': -1,
                  }"
                >
                  <div
                    v-for="(sub_menu, j) in item.children"
                    :key="index + '-' + j"
                    style="margin: 0 13px"
                  >
                    <a :href="sub_menu.path">{{ sub_menu.name }}</a>
                  </div>
                </div>
              </div>
              <!-- <div class="sg-index-menu">
                <div class="sg-first-menu-box">
                  <div class="sg-flex">
                    <div v-for="(item, index) in menus" :key="index" class="sg-first-menu">{{ item.name }}</div>
                  </div>
                </div>
              </div> -->
              <div
                :class="{
                  'sg-pad sg-bold sg-font-12px': true,
                  'sg-white': touchTopIndex,
                  'sg-gray': !touchTopIndex,
                }"
              >
                |
              </div>
              <el-button
                type="text"
                class="sg-pad sg-bold"
                @click.stop="switchShowSearch"
                ><i
                  :class="{
                    'el-icon-search sg-font-12px': true,
                    'sg-white': touchTopIndex,
                    'sg-gray': !touchTopIndex,
                  }"
                ></i
              ></el-button>
              <div
                class="sg-pad-lr sg-bold"
                @click="switchShowMenu"
                style="padding-right: 55px"
              >
                <el-button type="text"
                  ><i
                    :class="{
                      'el-icon-s-fold sg-font-12px': true,
                      'sg-white': touchTopIndex,
                      'sg-gray': !touchTopIndex,
                    }"
                  ></i
                ></el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <transition
        enter-active-class="sg-fade-down-menu-enter-active"
        leave-active-class="sg-fade-up-menu-leave-active"
      >
        <div
          @click.stop="stopTrans"
          v-show="showSearch"
          style="z-index: -1; position: relative"
        >
          <el-row class="sg-black-bg">
            <el-col
              :sm="{ span: 16, offset: 4 }"
              :xs="{ span: 24 }"
              style="padding: 69px 0"
            >
              <div class="sg-flex-center">
                <transition name="sg-fade-left-menu-search">
                  <div style="border-bottom: 1px solid gray" v-show="showSearch">
                    <input
                      @click.stop="stopTrans"
                      @keyup.enter="search"
                      placeholder="输入关键词查询"
                      class="sg-pad sg-gray-e4dfda sg-font-26px"
                      v-model="keyword"
                      style="
                        background: transparent;
                        box-shadow: none;
                        outline: none;
                        border: none;
                        width: 40vw;
                      "
                    />
                    <i
                      class="el-icon-search sg-font-icon sg-gray sg-margin-left"
                      style="color: #827F7D"
                      @click="search"
                    ></i>
                  </div>
                </transition>
              </div>
            </el-col>
          </el-row>
        </div>
      </transition>
    </div>
    <!-- <el-drawer
      title="我是标题"
      :visible="showMenu"
      :with-header="false"
      :size="'38%'"
      id="sg-index-drawer"
    > -->
    <div id="sg-index-drawer" :class="showMenu ? 'sg-show' : ''">
      <div class="sg-drawer-bg" v-show="showMenu" @click="switchShowMenu"></div>
      >
      <transition name="sg-drawer-fade-in-out">
        <div
          v-show="showMenu"
          class="sg-pad-bottom sg-drawer-content"
          style="
            padding-top: 31px;
            padding-left: 76px;
            padding-right: 41px;
            width: 657px;
            box-sizing: border-box;
          "
        >
          <div class="sg-flex-end sg-flex-column">
            <el-button @click="switchShowMenu" type="text" style="padding: 0"
              ><i
                class="el-icon-close sg-font sg-orange-bg sg-white sg-pointer"
                style="font-size: 23px; padding: 12px 11px 10px 12px"
              ></i
            ></el-button>
          </div>
          <img
            src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/logo-4.png"
            style="
              width: 166px;
              box-sizing: border-box;
              border-bottom: 1px solid #a7a7a7;
            "
            class="sg-logo sg-pad-tb sg-fade-left-0p5-8-enter-active"
          />
          <div class="sg-gray-4d sg-pad-tb sg-font-15px">
            服务产业园 · 助推新经济
          </div>
          <i class="el-icon-bottom sg-bold sg-orange"></i>
          <div style="margin-top: 69px; margin-right: 75px">
            <div
              class="sg-flex sg-border-bottom sg-fade-left-1-1p05-enter-active"
              style="padding: 0 0 22px 0; border-color: #a7a7a7"
            >
              <el-link
                :underline="false"
                class="sg-gray-44 sg-pad-right sg-font-16px"
                href="/star/intro"
                style="padding-right: 41px"
                >正启之星</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/star/intro"
                >企业介绍</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/star/dev"
                >发展历程</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/star/culture"
                >企业文化</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/star/core"
                >核心优势</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px"
                href="/star/serve"
                >服务客户</el-link
              >
            </div>
            <div
              class="sg-flex sg-border-bottom sg-fade-left-1-1p10-enter-active"
              style="padding: 25px 0 22px 0; border-color: #a7a7a7"
            >
              <el-link
                :underline="false"
                class="sg-gray-44 sg-pad-right sg-font-16px"
                href="/article-list"
                style="padding-right: 41px"
                >新闻中心</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px"
                href="/article-list"
                >观点文章</el-link
              >
            </div>
            <div
              class="sg-flex sg-border-bottom sg-fade-left-1-1p15-enter-active"
              style="padding: 25px 0 22px 0; border-color: #a7a7a7"
            >
              <el-link
                :underline="false"
                class="sg-gray-44 sg-pad-right sg-font-16px"
                href="/industry/finance"
                style="padding-right: 41px"
                >产业板块</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/industry/finance"
                >财税服务</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/industry/medical"
                >医疗服务</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/industry/building"
                >建筑服务</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px sg-pad-right"
                href="/industry/human"
                >人力资源</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px"
                href="/industry/education"
                >职业培训</el-link
              >
            </div>
            <div
              class="sg-flex sg-border-bottom sg-fade-left-1-1p20-enter-active"
              style="padding: 25px 0 22px 0; border-color: #a7a7a7"
            >
              <el-link
                :underline="false"
                class="sg-gray-44 sg-pad-right sg-font-16px"
                href="/hire"
                style="padding-right: 41px"
                >人力资源</el-link
              >
              <el-link
                :underline="false"
                class="sg-gray-b0aea2 sg-font-15px"
                href="/hire"
                >人才招聘</el-link
              >
            </div>
          </div>
          <div class="sg-flex-center">
            <img
              src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star_nav.png"
              class="sg-margin-bottom"
              style="margin-top: 68px; width: 480px"
            />
          </div>
        </div>
      </transition>
    </div>
    <!-- </el-drawer> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  name: "NavMenu",
  props: {},
  data: () => {
    return {
      menus: [
        { name: "首页", path: "/", left: "0", key: "index" },
        {
          name: "正启之星",
          path: "/star/intro",
          left: "50px",
          key: "star",
          children: [
            { name: "企业介绍", path: "/star/intro" },
            { name: "发展历程", path: "/star/dev" },
            { name: "企业文化", path: "/star/culture" },
            { name: "核心优势", path: "/star/core" },
            { name: "服务客户", path: "/star/serve" },
          ],
        },
        {
          name: "新闻中心",
          path: "/article-list",
          left: "128px",
          key: "article-list",
          children: [{ name: "观点文章", path: "/article-list" }],
        },
        {
          name: "产业板块",
          path: "/industry/finance",
          key: "industry",
          left: "106px",
          children: [
            { name: "财税服务", path: "/industry/finance" },
            { name: "医疗服务", path: "/industry/medical" },
            { name: "建筑服务", path: "/industry/building" },
            { name: "人力资源", path: "/industry/human" },
            { name: "职业培训", path: "/industry/education" },
          ],
        },
        {
          name: "人力资源",
          left: "284px",
          path: "/hire",
          key: "hire",
          children: [{ name: "人才招聘", path: "/hire" }],
        },
        // {
        //   name: "联系我们",
        //   path: "/contact"
        // },
      ],
      activeIndex: "0",
      // showMenu: false,
      // showSearch: false,
      // menu: "index",
      keyword: "",
      focus: false,
      hoverMenu: "",
    };
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.web.isLogin,
      userInfo: (state) => state.web.userInfo,
      screenSize: (state) => state.screenSize,
      showSearch: (state) => state.web.showSearch,
      touchTop: (state) => state.touchTop,
      menu: (state) => state.web.menu,
      showMenu: (state) => state.web.showMenu,
    }),
    twoMenus() {
      return this.menus.slice(1);
    },
    touchTopIndex() {
      return this.touchTop && this.menu == "index" && !this.focus;
    },
    activeMenu() {
      return this.hoverMenu ? this.hoverMenu : this.menu;
    },
    navBarW() {
      if (this.activeMenu == "index") return "50px";
      else return "78px";
    },
    navBarL() {
      if (this.activeMenu == "index") return "0";
      else if (this.activeMenu == "star") return "50px";
      else if (this.activeMenu == "article-list") return "128px";
      else if (this.activeMenu == "industry") return "206px";
      else if (this.activeMenu == "hire") return "284px";
      else return "0";
    },
  },
  mounted() {
    this.updateMenu();
    // this.getMenus();
    this.updateTouchTop()
  },
  methods: {
    updateTouchTop() {
      this.$store.commit('scrollAction')
    },
    navEnter(path) {
      this.hoverMenu = this.$util.parseMenu(path);
    },
    navLeave() {
      this.hoverMenu = "";
    },
    mouseEnter() {
      this.focus = true;
    },
    mouseLeave() {
      this.focus = false;
    },
    search() {
      let k = this.keyword.replace(/(^\s*)|(\s*$)/g, "");
      this.$store.commit("web/updateData", {
        name: "showSearch",
        data: false,
      });
      if (k != "") {
        window.location = "/article-list?k=" + k;
      }
    },
    stopTrans() {},
    updateMenu() {
      let path = this.$route.path;
      this.$util.updateMenu(path);
    },
    go(path) {
      this.$router.push({ path: path });
      this.$commit("");
      // this.showMenu = false;
      this.$store.commit("web/updateData", {
        name: "showMenu",
        data: false,
      });
    },
    switchShowMenu() {
      this.$store.commit("web/updateData", {
        name: "showMenu",
        data: !this.showMenu,
      });
      // this.showMenu = !this.showMenu;
      // let that = this
      // setTimeout(function() {
      //   that.showMenuContent = that.showMenu
      // }, 400)
    },
    switchShowSearch() {
      // this.showSearch = !this.showSearch;
      this.$store.commit("web/updateData", {
        name: "showSearch",
        data: !this.showSearch,
      });
    },
    openDialog(type) {
      this.$store.commit("web/switchLogin", {
        type: type,
        show: true,
      });
    },
    getMenus() {
      // this.$util.getData2(this, "categories/getAll", {}, "menus");
    },
    handleSelect() {},
    logout() {
      this.$store.commit("web/logout");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";

#sg-index-drawer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2000;
  overflow: auto;
  display: none;
  &.sg-show {
    display: block;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .sg-drawer-content {
    position: fixed;
    right: 0;
    top: 0;
    width: 38%;
    background: white;
    z-index: 2000;
    opacity: 0;
    animation: sg-drawer-fade-in-out-enter 0.4s ease-in-out 0.4s forwards;
    min-height: 100vh;
  }
  .sg-drawer-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 1000;
  }
}
// .sg-drawer-fade-in-out-enter-active {
//   animation: sg-drawer-fade-in-out-enter 0.4s ease-in-out 0.4s forwards;
// }
@keyframes sg-drawer-fade-in-out-enter {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.sg-menu-xs-box {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  background: white;
}
.sg-menu.el-link {
  border-bottom: 2px solid transparent;
}
.sg-menu.el-link:hover {
  color: $red;
  border-color: $red !important;
}
.sg-menu.sg-select {
  border-bottom: 2px solid $red;
}
.el-link:hover {
  color: $red;
}
.sg-route-index {
  transition: all 0.2s;
  .sg-menu.el-link.el-link--default {
    color: white;
  }

  &:hover {
    background: white;

    .sg-menu.el-link.el-link--default,
    div,
    i {
      color: #444 !important;
    }
  }
}
// .sg-index-menu {
//   .sg-first-menu {
//     list-style: none;
//     float: left;
//     font-size: 14px;
//     color: #616161;
//     font-weight: bold;
//     line-height: 80px;
//     padding: 0 14px;
//   }
// }
</style>
<style lang="scss">
@import "@/assets/scss/global.scss";
#sg-top-nav {
  &.el-menu {
    border-color: transparent;
  }
  .el-menu-item,
  .el-submenu__title {
    font-size: 14px;
    border-color: transparent;
    font-weight: bold;
    background: transparent !important;
    padding: 26px 11px 27px 11px;
    line-height: 14px;
    height: 67px;

    &:hover {
      border-color: transparent !important;
    }
  }
}

.sg-pop-menu {
  // transition: all 0.3s;
  // top: 0 !important;
  // &.el-zoom-in-top-enter-active,
  // &.el-zoom-in-top-leave-active
  z-index: 1000 !important;
  opacity: 1;
  &.el-zoom-in-top-enter {
    transform: translateY(-60px);
  }
  &.el-zoom-in-top-enter-active {
    transition: all 0.3s;
  }
  &.el-zoom-in-top-leave-active {
    transition: all 0.3s;
    transform: translateY(-60px);
  }
  &.el-zoom-in-top-leave-to {
    opacity: 0;
  }

  .el-menu--popup-bottom-start {
    margin-top: -1px;
  }
  .el-menu--popup {
    padding: 0;
  }
  .el-menu-item {
    font-size: 14px;
    height: 75px !important;
    line-height: 75px !important;

    &:hover {
      background: transparent !important;
    }
  }
  .el-menu {
    background: rgba(0, 0, 0, 0.6) !important;

    display: flex;

    .el-menu-item {
      color: #c5c6c8 !important;

      &:hover {
        color: #c73437 !important;
      }
    }
  }
}
.sg-menu-box {
  a {
    text-decoration: none;
  }
  .sg-nav-menu {
    .sg-nav-link {
      color: #444;
      padding: 26px 11px 27px 11px;
      line-height: 14px;
      height: 67px;
      display: inline-block;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
    }

    &.sg-touch-top {
      .sg-nav-link {
        color: white;
      }
    }
  }
}
.sg-sub-menu-box {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 67px;
  width: 100%;
  z-index: -100;
  opacity: 1;

  &.sg-touch-top {
    opacity: 0;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    color: #c5c6c8;
    line-height: 67px;

    &:hover {
      color: #c73437;
    }
  }
}
</style>
