<template>
  <div
    class="sg-gray-footer-bg sg-gray-3 sg-text-center sg-light-gray-bg" style="padding: 57px 0 59px 0"
  >
    <el-row>
      <el-col :sm="{ span: 20, offset: 2 }">
        <div class="sg-space-between sg-font-sm">
          <div class="sg-flex-center sg-gray-c6c6c5">
            <div class="sg-hover-white sg-pointer">正启之星（北京）企业管理有限公司</div>
            <div class="sg-pad-lr" style="padding: 0 12px;">|</div>
            <div class="sg-hover-white sg-pointer" @click="openNavMenu">网站地图</div>
            <div class="sg-pad-lr" style="padding: 0 12px;">|</div>
            <div class="sg-hover-white sg-pointer" @click="goLink('/contact')">联系我们</div>
            <div class="sg-pad-lr" style="padding: 0 12px;">|</div>
            <div>
              <el-tooltip
                placement="left"
                effect="light"
                popper-class="sg-web-group"
              >
                <div slot="content">
                  <div
                    class="sg-gray-c6c6c5 sg-gray-51-bg sg-pad sg-font-12px sg-space-between sg-text-left sg-pointer"
                    style="
                      border-right: 4px solid red;
                      border-image: linear-gradient(#d3100b, #f1853f) 30 30;
                    "
                  >
                    <div class="sg-margin-right">
                      <div
                        class="sg-pad-bottom sg-hover-white"
                        @click="goLink('https://www.sinbaad.com/about.html')"
                      >
                        薪八达人力
                      </div>
                      <div
                        class="sg-hover-white"
                        @click="goLink('https://www.mohurd.gov.cn/?medium=01')"
                      >
                        住房和城乡建设部
                      </div>
                    </div>
                    <div class="sg-margin-right">
                      <div
                        class="sg-pad-bottom sg-hover-white"
                        @click="goLink('http://www.chinatax.gov.cn/')"
                      >
                        国家税务总局
                      </div>
                      <div
                        class="sg-gray-c6c6c5 sg-hover-white"
                        @click="goLink('http://www.moe.gov.cn/')"
                      >
                        国家教育部
                      </div>
                    </div>
                    <div>
                      <div
                        class="sg-pad-bottom sg-hover-white"
                        @click="goLink('http://www.mohrss.gov.cn/')"
                      >
                        国家人力资源和社会保障部
                      </div>
                      <div @click="goLink('http://www.nhsa.gov.cn/')" class="sg-hover-white">
                        国家医疗保障局
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sg-font-12px sg-hover-white sg-pointer">正启之星网群</div>
              </el-tooltip>
            </div>
            <img
              src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/phone.png"
              style="width: 21px"
              class="sg-pad-left-md"
            />
            <div style="color: #FFA100; font-size: 13px;">客服电话</div>
            <div style="color: #FFA100; font-size: 20px;" class="sg-font-icon sg-pad-left">
              400 058 1288
            </div>
          </div>
          <el-tooltip placement="left">
            <div slot="content">
              <img style="width: 100px" src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/qr-code.png?t=111" />
            </div>
            <div class="sg-flex-center">
              <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/wechat.png" style="width: 23px" />
              <div class="sg-pad-left" style="font-size: 13px;">关注正启之星</div>
            </div>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Tempalte",
  props: {},
  data: () => {
    return {};
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    openNavMenu() {
      this.$store.commit("web/updateData", {
        name: "showMenu",
        data: true,
      });
    },
    goLink(url) {
      window.open(url);
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-hover-white:hover {
  color: white;
}
</style>
